import React from 'react';

const ComoFunciona = () => {
    return (
<div className="container mx-auto p-6 shadow-lg rounded-lg mb-5 mt-5">
        <h2 className="text-2xl font-bold mb-8 text-center">¿CÓMO FUNCIONA?</h2>
        <div className="flex flex-col md:flex-row items-center space-y-6 md:space-y-0 md:space-x-8">
            <div className="flex flex-col items-center space-y-4">
                <img src="/images/casa.png" alt="Limpieza" className="w-20 h-20"/> 
                <p className="text-center text-gray-700">¿Tienes un espacio que requiere limpieza o mantenimiento?</p>
            </div>
            <div className="flex flex-col items-center space-y-4 text-gray-700">
                <img src="/images/imagen5.png" alt="Limpieza" className="w-20 h-20"/>
                <p className="text-center">Limpieza profunda de casa, departamento, oficina y negocio. Terminación de obra</p>
            </div>
            <div className="flex flex-col items-center space-y-4 text-gray-700">
                <img src="/images/herramientas.png" alt="Mantenimiento" className="w-20 h-20"/>
                <p className="text-center">Mantenimiento en electricidad, plomería, carpintería, pintura y albañilería.</p>
            </div>
            <div className="flex flex-col items-center space-y-4 text-gray-700">
                <img src="/images/contact.png" alt="Contacto" className="w-20 h-20"/>
                <p className="text-center">Contáctanos y nosotros lo hacemos por ti.</p>
            </div>
        </div>
    </div>
    );
}

export default ComoFunciona;